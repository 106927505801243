import React, { useEffect, useContext } from 'react'
import { ContextLoader } from '../context'
import loadFonts from '../helpers/loadFonts'

const Loader = () => {

	const [isLoading, setIsLoading] = useContext(ContextLoader)

	// Launch assets loading
	useEffect(() => {
		handleFontsLoad().then(() => {
			setIsLoading(false)
		})
	}, [setIsLoading])

	// Load fonts
	const handleFontsLoad = () => {
		return new Promise(
			(resolve) => {
				loadFonts().then(() => {
					resolve()
				})
			}
		)
	}

	return (
		<div className={`Loader ${isLoading === false ? 'isEnded' : ''}`}>

		</div>
	)
}

export default Loader