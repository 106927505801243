const FontFaceObserver = require('fontfaceobserver')

const fontsData = {
  'Mabry Pro': { weight: 'normal' },
  'Mabry Mono Pro': { weight: 'normal' }
};

const defaultFonts = [
  'Mabry Pro',
  'Mabry Mono Pro'
]

function loadFonts(fonts = defaultFonts) {
  return new Promise((resolve) => {
    const fontFaceObersvers = fonts.map(font => {
      const data = fontsData[font]
      return new FontFaceObserver(font, data)
    })
    Promise.all(fontFaceObersvers).then(() => {
      resolve()
    });
  })
}

export default loadFonts;