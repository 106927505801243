class DetectColor {
  constructor() {
    this.$ = {
			sections: null,
			sectionsMobile: null,
			navMaskLeft: null
		}
		this.detectColor = this.detectColor.bind(this)
		this.onResize = this.onResize.bind(this)
		this.menuTop = 0
		this.menuRight = 0
		this.isMobile = false
		this.homeSecondFirstPanelNotchWidth = 0
		this.homeSecondFirstPanelNotchHeight = 0
		this.currentSectionColor = null
  }

  init() {
		this.currentSectionColor = null
		this.$.sections = [...document.querySelectorAll('[data-menu-color]')]
		this.$.sectionsMobile = [...document.querySelectorAll('[data-menu-mobile-color]')]
		this.$.navMaskLeft = document.querySelector('.Nav__Mask--Left')
		window.addEventListener('resize', this.onResize)
		this.onResize()
		window.addEventListener('scroll', this.detectColor)
		document.addEventListener('DOMContentLoaded', () => {
			this.detectColor()
		})
		this.detectColor()
		setTimeout(() => {
			this.detectColor()
		}, 500)
	}

	destroy() {
		if (this.currentSectionColor) {
			const prevColorsAsArray = this.currentSectionColor.split(',')
			document.body.classList.remove(...prevColorsAsArray)
			this.currentSectionColor = null
		}
		window.removeEventListener('resize', this.onResize)
		window.removeEventListener('scroll', this.detectColor)
	}

	detectColor() {
		let newSectionColor = null
		if (this.isMobile === false) {
			this.$.sections.forEach((section, index) => {
				const sectionRect = section.getBoundingClientRect()
				if (sectionRect.top - this.menuTop <= 0 && sectionRect.bottom - this.menuTop > 0 && sectionRect.left < this.menuRight) {
					newSectionColor = section.getAttribute('data-menu-color')
					// Color mask for .HomeSecond__RightFirstPanel
					if (section.className.includes('HomeSecond')) {
						const firstPanelEl = section.querySelector('.HomeSecond__RightFirstPanel')
						const firstPanelRect = firstPanelEl.getBoundingClientRect()
						if (firstPanelRect.top < -this.homeSecondFirstPanelNotchHeight) {
							this.$.navMaskLeft.style.width = firstPanelRect.left + 'px'
						} 
						else if (firstPanelRect.top === 0) {
							this.$.navMaskLeft.style.width = firstPanelRect.left - this.homeSecondFirstPanelNotchWidth + 'px'
						}
						else {
							this.$.navMaskLeft.style.width = 0
						}
					} 
					// else if (section.className.includes('ScheduleHero')) {
					// 	this.$.navMaskLeft.style.width = this.homeSecondFirstPanelNotchWidth + 'px'
					// }
					else {
						this.$.navMaskLeft.style.width = 0
					}
				}
			})
		} else {
			this.$.sectionsMobile.forEach((section, index) => {
				const sectionRect = section.getBoundingClientRect()
				if (sectionRect.top - this.menuTop <= 0 && sectionRect.bottom - this.menuTop > 0) {
					newSectionColor = section.getAttribute('data-menu-mobile-color')
				}
			})
		}
		if (this.currentSectionColor !== newSectionColor) {
			if (this.currentSectionColor !== null) {
				document.body.classList.remove(this.currentSectionColor)
			}
			this.currentSectionColor = newSectionColor
			document.body.classList.add(this.currentSectionColor)
		}
	}

	onResize() {
		const ww = window.innerWidth
		this.menuTop = ww * 0.038
		this.menuRight = ww * 0.3333333333
		this.homeSecondFirstPanelNotchWidth = ww * 0.0825
		this.homeSecondFirstPanelNotchHeight = ww * 0.1
		this.isMobile = ww < 1024
	}

}

export default DetectColor
