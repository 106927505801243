import React, { useState } from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"

const timeoutExit = 1000
const timeoutEnter = 1000

const Transition = ({ children, location }) => {

	const [isTransitioning, setIsTransitioning] = useState(false)

	const handleExit = () => {
		setIsTransitioning(true)
		setTimeout(() => {
			window.scrollTo(0, 0)
		}, timeoutExit)
	}

	const handleEnter = () => {
		setIsTransitioning(false)
	}

	return (
		<>
			<SwitchTransition mode="out-in">
				<CSSTransition
					key={location.pathname}
					timeout={{
						exit: timeoutExit,
						enter: timeoutEnter
					}}
					onExit={handleExit}
					onEnter={handleEnter}
				>
					{children}
				</CSSTransition>
			</SwitchTransition>
			<div className={`Transition ${isTransitioning ? 'isVisible' : ''}`}></div>
		</>
	)

}
export default Transition