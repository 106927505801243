import React, { useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import clamp from '../helpers/clamp'
import lerp from "../helpers/lerp"
import useOnScreen from '../hooks/useOnScreen'

const ModulesLottieAnimation = ({ animation = null, className = '', speed = 1 }) => {

	// Hero Lottie anim
	const lottieContainerEl = useRef(null)
	const lottieAnim = useRef(null)
	const framesLength = useRef(null)

  useEffect(() => {
		lottieAnim.current = lottie.loadAnimation({
			container: lottieContainerEl.current,
			renderer: 'svg',
			loop: false,
			autoplay: false,
			animationData: animation
		})
		framesLength.current = lottieAnim.current.getDuration(true)
		return function clear () {
			lottieAnim.current.destroy();
		}
	}, [])

	// Update lottie anim on scroll
  const isOnScreen = useOnScreen(lottieContainerEl)
	const targetProgressY = useRef(0)
	const progressY = useRef(0)
  useEffect(() => {
    let rafId = null
    let wh = null

    const handleResize = () => {
      wh = window.innerHeight
    }

    const handleScroll = () => {
      targetProgressY.current = clamp(1 - (lottieContainerEl.current.getBoundingClientRect().top / wh), 0, 1) * speed
    }

    const loop = () => {
      // Update lines
      progressY.current = lerp(progressY.current, targetProgressY.current, 0.1)
      lottieAnim.current.goToAndStop(Math.floor(progressY.current * framesLength.current), true)
      rafId = requestAnimationFrame(loop)
    }

    if (isOnScreen === true) {
      window.addEventListener('resize', handleResize)
      handleResize()
      window.addEventListener('scroll', handleScroll)
      rafId = requestAnimationFrame(loop)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
      cancelAnimationFrame(rafId)
    }
  }, [isOnScreen])

	return (
		<div className={`ModulesLottieAnimation ${className}`} ref={lottieContainerEl}></div>
	)
}

export default ModulesLottieAnimation