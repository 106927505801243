import React, { useRef, useEffect } from 'react'
import useOnScreen from '../hooks/useOnScreen'
import clamp from '../helpers/clamp'

const Mask = ({ children, speed = 0.5, isFullScreen = false, onLeaving = false, lastInPage = false, disabledOnMobile = false }) => {

	const containerEl = useRef(null)
	const maskEl = useRef(null)
	const rafId = useRef(null)
	const isOnScreen = useOnScreen(containerEl)

	/**
	 * Animations when on screen
	 */
	 useEffect(() => {
		if (disabledOnMobile === true && localStorage.getItem('is-touch') === 'true') return
		let wh = 0

		const loop = () => {
			const containerElRect = containerEl.current.getBoundingClientRect()
			let containerElProgress = 0
			// Even if element is last in page, if the containers height is higher than the windows height, do the normal calculation.
			if (lastInPage === false || containerElRect.height > wh) {
				const progressScale = containerElRect.height > wh ? containerElRect.height : wh
				containerElProgress = onLeaving === false ? clamp(containerElRect.top / progressScale, 0, 1) * speed : clamp(containerElRect.top / progressScale, -1, 0) * speed
			} else {
				const diffHeightWh = wh - containerElRect.height
				containerElProgress = clamp((containerElRect.top - diffHeightWh) / wh, 0, 1) * speed
			}
			if (isFullScreen) {
				maskEl.current.style.transform = `translate3d(0, ${containerElProgress * -100}vh, 0)`
			} else {
				maskEl.current.style.transform = `translate3d(0, ${containerElProgress * -containerElRect.height}px, 0)`
			}

			rafId.current = requestAnimationFrame(loop)
		}

		const handleResize = () => {
			wh = window.innerHeight
		}

		if (isOnScreen === true) {
			window.addEventListener('resize', handleResize)
			handleResize()
			rafId.current = requestAnimationFrame(loop)
		} else {
			cancelAnimationFrame(rafId.current)
			window.removeEventListener('resize', handleResize)
		}
		return () => {
			cancelAnimationFrame(rafId.current)
			window.removeEventListener('resize', handleResize)
		}
	}, [isOnScreen])

	return (
		<div className={`Mask ${disabledOnMobile === true ? 'disabledOnMobile' : ''}`} ref={containerEl}>
			<div className="Mask__Container" ref={maskEl}>
				{children}
			</div>
		</div>
	)
}

export default Mask