import React, { useState, useRef, useEffect } from 'react'
import { ContextLoader } from '../context'
import Loader from '../components/Loader'
import Nav from '../components/Nav'
import Cursor from '../components/Cursor'
import Footer from '../components/Footer'
import Transition from '../components/Transition'
import Burger from '../components/Burger'
import Seo from './Seo'
import '../styles/style.scss'
import '../helpers/noSSR'
import DetectColor from '../helpers/detectColor'
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter'
import Mask from './Mask'
import Grain from '../images/grain.png'
import anim04 from '../images/lottie/04.json';
import ModulesLottieAnimation from './ModulesLottieAnimation'

const Layout = ({ children, location, data }) => {

	const [isLoading, setIsLoading] = useState(true)

	const detectColor = useRef(new DetectColor())
	const detectColorTimeoutId = useRef(null)
	useEffect(() => {
		detectColorTimeoutId.current = setTimeout(() => {
			clearTimeout(detectColorTimeoutId.current)
			detectColor.current.destroy()
			detectColor.current.init()
		}, 2000)
	}, [location.pathname])

	// Seo
	const seoFormattedPageName = getSeoFormattedPageName(location.pathname)
	const metaDescription = data.prismicSeo ? data.prismicSeo.data.meta_description.text : ''

	return (
		<div className={`TechWeek ${location.pathname.replaceAll('/', '')} ${location.pathname === '/' ? 'home' : ''}`}>
			<ContextLoader.Provider value={[isLoading, setIsLoading]}>
				<Seo
					title={seoFormattedPageName}
					description={metaDescription}
				/>
				<div 
					className="TechWeek__Grain"
					style={{ backgroundImage: `url(${Grain})` }}
				></div>
				{/* <div className="TechWeek__Blur"></div> */}
				<Loader/>
				<Nav pathname={location.pathname}/>
				<Burger pathname={location.pathname}/>
				<Transition location={location}>
					<div className="TechWeek__Page">
						{children}
					</div>
				</Transition>
				<div className="Footer__MaskWrapper">
					<ModulesLottieAnimation animation={anim04} className="Footer__ModulesAnimation" speed={1.4545454545}/>
					<Mask lastInPage={true}>
						<Footer pathname={location.pathname} dates={data.prismicFooter.data.dates}/>
					</Mask>
				</div>
				<Cursor/>
			</ContextLoader.Provider>
    </div>
	)
}

const getPageNameFromPathname = pathname => {
	if (pathname === '/') {
		return 'homepage'
	} else {
		return pathname.replaceAll('/', '')
	}
}

const getSeoFormattedPageName = pathname => {
	if (pathname === '/') {
		return ''
	} else {
		const pageName = getPageNameFromPathname(pathname)
		return capitalizeFirstLetter(pageName)
	}
}

export default Layout