import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import socialMediaImage from "../images/share.jpg"

const Seo = ({
	title,
	description = ''
}) => {

	const formattedTitle = title ? `The Tech Week | ${title}` : 'The Tech Week'

  return (
    <Helmet title={formattedTitle}>

      {title && <meta property="og:title" content={formattedTitle} />}
			{title && <meta name="twitter:title" content={formattedTitle} />}

      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
			{description && <meta name="twitter:description" content={description} />}

			<meta property='og:url' content='https://thetechweek.com/'/>

      <meta property="image" content={socialMediaImage} />
      <meta property="og:image" content={socialMediaImage} />

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      
    </Helmet>
  )
}

export default Seo

export const query = graphql`
	fragment Seo on PrismicSeo {
		data {
      meta_description {
        text
      }
    }
	}
`