import React, { useRef, useEffect, useState } from 'react'
import CursorImage from '../images/cursor.png'
import lerp from '../helpers/lerp'

const Cursor = () => {

	const cursorEl = useRef(null)

	useEffect(() => {
		if (localStorage.getItem('is-touch') === 'true') return
		
		let rafId = null
		let targetX = 0
		let targetY = 0
		let targetScale = 1
		let x = 0
		let y = 0
		let scale = 1

		const loop = () => {
			x = lerp(x, targetX, 0.1)
			y = lerp(y, targetY, 0.1)
			scale = lerp(scale, targetScale, 0.1)
			cursorEl.current.style.transform = `translate3d(${x}px, ${y}px, 0) scale(${scale})`
			rafId = requestAnimationFrame(loop)
		}

		const handleMouseMove = e => {
			targetX = e.clientX
			targetY = e.clientY
			// Link detect
			const closestParentLink = e.target.closest('[data-is-link]')
			if (closestParentLink !== null) {
				targetScale = 1.4
			} 
			else if (closestParentLink === null) {
				targetScale = 1
			}
		}

		window.addEventListener('mousemove', handleMouseMove)
		rafId = requestAnimationFrame(loop)
		return () => {
			window.removeEventListener('mousemove', handleMouseMove)
			cancelAnimationFrame(rafId)
		}
	}, [])

	return (
		<div className="Cursor" ref={cursorEl}>
			<img
				className='Cursor__Image'
				src={CursorImage}
			/>
		</div>
	)
}

export default Cursor